@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto&display=swap");
@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif;
}

.home-container {
  display: flex;
  height: 100vh;
}

.home-left {
  width: 10%;
}

.home-middle {
  width: 75%;
}

.home-right {
  width: 15%;
  box-shadow: -6px 0 5px -6px rgba(191, 190, 190, 0.75);
}

.home-right-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
}

.home-right-card1 {
  /* margin-bottom: 1.5vw; */
  /* width: 10vw; */
  /* height: 9vw; */
  /* border-radius: 0.5vw; */
  background: linear-gradient(#bdc7f1, #384b9d);
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.home-right-card2 {
  /* width: 10vw;
  height: 9vw;
  border-radius: 0.5vw; */
  background: linear-gradient(#bdc7f1, #384b9d);
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.business-card-img {
  width: 4.5vw;
  height: 4.5vw;
}

.business-card-txt-1 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 0.9vw;
  font-weight: 600;
  color: #fbfbfb;
}

.business-card-txt-2 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 0.45vw;
  font-weight: 600;
  color: #fbfbfb;
  margin-top: 0.5vw;
}

.home-middle-heading {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1.8vw;
  line-height: 2vw;
  color: #0e3293;
}

.home-middle-sub-heading {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 600;
  line-height: 2vw;
  letter-spacing: 0em;
  color: #000000;
  margin-left: 0.2vw;
}

.home-middle-details {
  /* display: flex; */
  /* margin-top: 1.7vw; */
  /* width: 98%; */
  /* height: 7.5vw; */
  /* border-radius: 0.5vw; */
  background: linear-gradient(to right, #2c3d88, #4355a4);
  /* justify-content: space-between; */
}

.home-middle-card-button-img {
  margin-right: 0.4vw;
  width: 0.7vw;
  height: 0.7vw;
}

.home-middle-details-img {
  position: absolute;
  width: 80%;
  height: 5vw;
  margin-top: 1.6vw;
}

.home-middle-details-img2 {
  position: absolute;
  width: 80%;
  height: 5vw;
  margin-top: 2.6vw;
}

.home-middle-details-img3 {
  position: absolute;
  margin-top: 3.3vw;
  margin-left: 0.7vw;
  width: 0.7vw;
  height: 0.7vw;
}

.home-middle-details-img4 {
  position: absolute;
  margin-left: 61vw;
  margin-top: 2.2vw;
  width: 1vw;
  height: 1vw;
}

.home-middle-details-img5 {
  position: absolute;
  margin-left: 59vw;
  margin-top: 4vw;
  width: 0.8vw;
  height: 0.8vw;
}

.home-middle-details-img6 {
  position: absolute;
  margin-left: 71vw;
  margin-top: 2.55vw;
  width: 0.6vw;
  height: 0.6vw;
}

.home-middle-details-img7 {
  position: absolute;
  margin-left: 16.7vw;
  width: 3vw;
  height: 1.4vw;
}

.home-middle-details-img8 {
  position: absolute;
  margin-top: 6vw;
  margin-left: 34.9vw;
  width: 3vw;
  height: 1.4vw;
}

.customer-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5vh;
}

/* .customer-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3vw;
  width: 98%;
} */

.customer-label {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 400;
  color: #384b9d;
  margin-bottom: 0.7vw;
}
/* 
.customer-text-box {
  width: 20vw;
  outline: 0;
  border-radius: 1vw;
  padding: 1.1vw;
  border: 1px solid #d6d5d5;
  box-sizing: border-box;
  height: 3.8vw;
  box-shadow: 0px 3px 8px #bbbaba;
  font-size: 0.8vw;
  color: #666666;
  font-weight: 400;
} */

/* .customer-button {
  display: flex;
  justify-content: end;
  margin-right: 2vw;
  margin-top: 3vw;
} */

.customer-left-button {
  width: 7vw;
  height: 2.6vw;
  margin-left: 0.6vw;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 500;
  color: #ffffff;
  background: #ca5656;
  outline: 0;
  border: none;
  border-radius: 0.4vw;
  cursor: pointer;
}

.customer-left-button:hover {
  background: #a23f3f;
}

.customer-right-button {
  width: 11vw;
  height: 2.6vw;
  margin-left: 0.6vw;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 500;
  color: #ffffff;
  background: #3850bd;
  outline: 0;
  border: none;
  border-radius: 0.4vw;
  cursor: pointer;
}

.customer-right-button:hover {
  background: #273c9c;
}

.sale-label {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 400;
  color: #384b9d;
  margin-bottom: 0.7vw;
}

.sale-text-box {
  width: 100%;
  outline: 0;
  border-radius: 1vw;
  padding: 1.1vw;
  border: 1px solid #d6d5d5;
  box-sizing: border-box;
  height: 3.8vw;
  box-shadow: 0px 3px 8px #bbbaba;
  font-size: 0.8vw;
  color: #666666;
  font-weight: 400;
  margin-bottom: 1.8vw;
}

.product-label {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 400;
  color: #384b9d;
  margin-bottom: 0.7vw;
}

select {
  -webkit-appearance: none;
  width: 50px;
}

.product-button {
  display: flex;
  justify-content: end;
  margin-right: 3vw;
  margin-top: 1vw;
  padding-bottom: 1vw;
}

.finance-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  margin-top: 3vw;
}

.finance-div {
  background: linear-gradient(#8f9cd5, #4355a4);
}

.finance-text1 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  color: #ececec;
  font-size: 1.2vw;
  line-height: 2vw;
}

.finance-text2 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 1.7vw;
  font-weight: 700;
  line-height: 3vw;
  letter-spacing: 0em;
  text-align: left;
}

/* check from here */

/* .finance-box {
  margin-top: 2vw;
  width: 97%;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* .finance-box-div {
  width: 23.5vw;
  height: 6vw;
  border-radius: 0.2vw;
  display: flex;
  align-items: center;
  box-shadow: -1px 3px 8px #bbbaba;
} */

.finance-box-img-div {
  padding: 0.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ededed;
  border-radius: 0.5vw;
  margin: 0 1vw;
}

.finance-box-img {
  width: 1vw;
  height: 1.2vw;
}

.finance-box-txt1 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.9vw;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0.4vw;
}

.finance-box-txt2 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 0.6vw;
  color: #6d6d6d;
}

.invoice-box {
  display: flex;
  justify-content: flex-start;
  width: 97%;
  margin-top: 4vw;
  height: auto;
  flex-wrap: wrap;
}

.invoice-container {
  display: flex;
  justify-content: space-between;
  width: 30vw;
  height: 7.5vw;
  /* padding: 1vw; */
  box-shadow: 0px 3px 8px #bbbaba;
  border-radius: 0.3vw;
  margin-bottom: 2.5vw;
  margin-left: 3vw;
  margin-right: 2vw;
}

.invoice-div {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  margin-left: 1vw;
}

.invoice-txt1 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.5vw;
  font-weight: 600;
  line-height: 2vw;
  color: #3850bd;
  /* margin-bottom: 0.5vw; */
}

.invoice-txt2 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 600;
  color: #000000;
}

.invoice-button {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 600;
  width: 6vw;
  background: #384b9d;
  color: #fbfbfb;
  border: none;
  height: 2vw;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invoice-button:hover {
  background: #1f338b;
}

.business-container {
  width: 12vw;
  position: absolute;
  background: #fff;
  z-index: 2;
  /* margin-bottom: -vw; */
  margin-top: 25vw;
  box-shadow: -1px 3px 8px #bbbaba;
  border-radius: 0.4vw;
  color: #706e6e;
  padding: 0.5vw 1vw;
  cursor: grab;
}

.business-container p {
  margin-bottom: 0.3vw;
  font-size: 0.8vw;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.business-container p:hover {
  background: #ebeaea;
}

.business-btn {
  margin-top: 1vw;
  width: 100%;
  height: 2.1vw;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.7vw;
  font-weight: 500;
  color: #ffffff;
  background: #3850bd;
  outline: 0;
  border: none;
  border-radius: 0.3vw;
  cursor: pointer;
}

.business-btn:hover {
  background: #19319c;
}

.new-acc-container {
  margin-top: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.new-acc-label {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.9vw;
  font-weight: 400;
  color: #384b9d;
  margin-bottom: 0.7vw;
}

.new-acc-text-box {
  width: 20vw;
  outline: 0;
  border-radius: 1vw;
  padding: 1.1vw;
  border: 1px solid #d6d5d5;
  box-sizing: border-box;
  height: 3vw;
  box-shadow: 0px 3px 8px #bbbaba;
  font-size: 0.6vw;
  color: #666666;
  font-weight: 400;
  margin-bottom: 1vw;
}

.new-acc-left-button {
  width: 9vw;
  height: 2.6vw;
  margin-left: 0.6vw;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 500;
  color: #ffffff;
  background: #ca5656;
  outline: 0;
  border: none;
  border-radius: 0.4vw;
  cursor: pointer;
}

.new-acc-right-button {
  width: 9vw;
  height: 2.6vw;
  margin-left: 0.6vw;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 500;
  color: #ffffff;
  background: #3850bd;
  outline: 0;
  border: none;
  border-radius: 0.4vw;
  cursor: pointer;
}

.new-acc-heading {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1.8vw;
  color: #0e3293;
}

.new-acc-file-box {
  width: 20vw;
  font-size: 0.7vw;
  margin-bottom: 1vw;
}

.money-container {
  display: flex;
  flex-direction: column;
  width: 30vw;
  height: 7.5vw;
  box-shadow: 0px 3px 8px #bbbaba;
  border-radius: 0.3vw;
  margin-bottom: 2.5vw;
  margin-left: 3vw;
  margin-right: 2vw;
  padding: 0.6vw 1vw;
}

.money-div {
  display: flex;
  flex-direction: column;
}

.money-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.money-txt1 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.1vw;
  font-weight: 600;
  line-height: 2vw;
  color: #3850bd;
}

.money-txt2 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.7vw;
  font-weight: 600;
  color: #000000;
}

.money-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.7vw;
}

.money-button {
  width: 4vw;
  height: 1.7vw;
  /* margin-left: 0.6vw; */
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.7vw;
  font-weight: 500;
  /* line-height: 1vw; */
  /* letter-spacing: 0em; */
  color: #ffffff;
  background: #384b9d;
  outline: 0;
  border: none;
  border-radius: 0.3vw;
  cursor: pointer;
  margin-left: 0.4vw;
}

.money-txt3 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.9vw;
  font-weight: 600;
}

.money-bottom-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.amount-container {
  /* width: 97%; */
  /* height: 7vw; */
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* border-radius: 0.2vw; */
  /* margin-top: 1vw; */
  background: linear-gradient(#97a4db, #384b9d);
}

.amount-txt-container {
  margin-right: 5vw;
}

.amount-box {
  margin-left: 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amount-img {
  width: 3vw;
  /* height: 1w; */
  border-radius: 10vw;
  margin-right: 1vw;
}

.amount-txt1 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.3vw;
  font-weight: 700;
  color: #fbfbfb;
}

.amount-txt2 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.3vw;
  font-weight: 700;
  color: #fbfbfb;
}

.amount-sale-text-box {
  width: 97%;
  outline: 0;
  border-radius: 1vw;
  padding: 1.1vw;
  border: 1px solid #d6d5d5;
  box-sizing: border-box;
  height: 3.8vw;
  box-shadow: 0px 3px 8px #bbbaba;
  font-size: 0.8vw;
  color: #666666;
  font-weight: 400;
  margin-bottom: 1.8vw;
}

.amount-button {
  width: 12vw;
  height: 2.6vw;
  margin-left: 0.6vw;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 500;
  color: #ffffff;
  background: #3850bd;
  outline: 0;
  border: none;
  border-radius: 0.4vw;
  cursor: pointer;
}

.amount-button:hover {
  background: #273c9c;
}

/* .amount-button-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
} */

.amount-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: fixed; */
  /* height: 330px; */
  width: 97%;
  margin-top: 4vw;
}

.amount-txt3 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 600;
}

.amount-button-box {
  display: flex;
  align-items: center;
}
/* .amount-button-container > div {
  display: inline-block; 
} */

/* .amount-button {
  
} */

.amount-slider-container {
  position: relative;
  display: inline-block;
  width: 2.2rem;
  height: 1rem;
  margin-left: 1rem;
  background: white;
}

.amount-slider-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 0.6rem;
  width: 0.6rem;
  left: 0.2rem;
  bottom: 0.2rem;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.2rem);
  -ms-transform: translateX(1.2rem);
  transform: translateX(1.2rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 1vw;
}

.slider.round:before {
  border-radius: 50%;
}

.invoice-button1 {
  width: 19vw;
  height: 3.8vw;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 500;
  color: #ffffff;
  background: #384b9d;
  outline: 0;
  border: none;
  border-radius: 1vw;
  cursor: pointer;
  margin-bottom: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoice-button1:hover {
  background: #273c9c;
}

.invoice-button2 {
  width: 7vw;
  height: 2vw;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 500;
  color: #ffffff;
  background: #384b9d;
  outline: 0;
  border: none;
  border-radius: 0.6vw;
  cursor: pointer;
  margin-bottom: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vw;
  margin-left: 1vw;
}

.invoice-button2:hover {
  background: #253a9a;
}

.create-invoice-container {
  margin-top: 2vw;
  background: #ededed;
  padding: 2vw;
  margin-bottom: 3vw;
  width: 98%;
}

.create-invoice-div {
  display: flex;
  justify-content: space-between;
}

/* .hello{
  width: 0.8vw;
  height: 0.7vw;
}

.hii{
  background: #384B9D;
  border-radius: 12vw;
  width: 1.4vw;
  height: 1.4vw;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.add-product-container {
  background: #fbfbfb;
  height: 15vw;
  margin-bottom: 2vw;
  border-radius: 0.5vw;
  box-shadow: -1px 3px 8px #bbbaba;
  border: 1px solid #999999;
  /* padding: 1vw; */
  /* display: flex; */
}

.add-product-header {
  height: 4vw;
  display: flex;
  align-items: center;
  border-bottom: 1px dotted #c0c0c0;
}

.table-container,
.table-header,
.table-data {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 400;
  text-align: center;
  color: #666666;
}

/* .table-header{
  color: #424242;
} */

.table-data {
  padding: 0.5vw;
  z-index: 1;
}

.table-header-container {
  background-color: #fffafa;
}

.table-data button {
  width: 3.2vw;
  height: 1.5vw;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.6vw;
  font-weight: 500;
  color: #ffffff;
  background: #4f61b1;
  outline: 0;
  border: none;
  border-radius: 0.6vw;
  cursor: pointer;
}

.product-label-div {
  background: white;
  height: 3.8vw;
  display: flex;
  align-items: center;
  padding: 1.1vw;
  width: 19vw;
  border-radius: 1vw;
  box-shadow: 0px 3px 8px #bbbaba;
  color: #666666;
  font-size: 0.8vw;
  margin-bottom: 2vw;
}

.invoice-label-div {
  background: white;
  height: 3.8vw;
  display: flex;
  align-items: center;
  padding: 1.1vw;
  width: 33vw;
  border-radius: 1vw;
  box-shadow: 0px 3px 8px #bbbaba;
  color: #666666;
  font-size: 0.8vw;
  margin-bottom: 2vw;
}

.product-label-input {
  font-size: 0.8vw;
}

.create-sale-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 33vw;
  height: 4vw;
  padding: 1.3vw;
  border: 1px solid #d6d5d5;
  box-shadow: 0px 3px 8px #bbbaba;
  border-radius: 1vw;
  background: white;
}

.selected-data {
  position: absolute;
  z-index: 10;
  background: rgb(204, 203, 203);
  font-family: "Nunito Sans", sans-serif;
  width: 19vw;
  border-radius: 1vw;
  padding: 1vw;
  margin-top: -1vw;
  height: 14vw;
  overflow-y: scroll;
}

.inventory-card-container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 81%;
  padding: 0vw 2vw;
}

.inventory-card-box {
  width: 30vw;
  height: auto;
  margin-top: 2vw;
  box-shadow: -1px 3px 8px #bbbaba;
  padding: 1vw;
}

.inventory-card-img {
  width: 3.4vw;
  /* height: 10vw; */
  border-radius: 0.2vw;
  margin-right: 0.5vw;
}

.inventory-card-txt {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 600;
  color: #000000;
}

.inventory-card-right-txt{
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 600;
  color: #384B9D;
}

.inventory-card-data{
  margin-top: 1vw;
}

.inventory-card-txt1{
  margin-right: 10%;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.7vw;
  font-weight: 600;
  color: #3A3A3A;
}

.inventory-stock-card-box {
  width: 30vw;
  height: auto;
  margin-top: 2vw;
  box-shadow: -1px 3px 8px #bbbaba;
}

.inventory-card-txt2{
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.7vw;
  font-weight: 600;
  color:#384B9D;
}

.inventory-stock-card-txt{
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.9vw;
  font-weight: 600;
}

.inventory-stock-card-txt1{
  /* margin-right: 10%; */
  width: 40%;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.7vw;
  font-weight: 600;
}

.inventory-stock-card-data{
  margin-top: 1vw;
  height: 3.5vw;
  border-bottom: 1px solid #999999;
}

.inventory-stock-card-txt2{
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.7vw;
  font-weight: 600;
  color: #384B9D;
}

.staff-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  margin-top: 1.5vw;
}

.staff-div {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: row; */
  /* margin-left: 1vw; */
}

.staff-img {
  width: 1.8vw;
  height: 2vw;
  border-radius: 0.2vw;
  margin-right: 0.5vw;
}

.staff-txt2 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.7vw;
  font-weight: 600;
  color: #000000;
}

.staff-btn-div{
  display: flex;
  justify-content: space-between;
  margin-top: 1vw;
}

.staff-button {
  width: 6vw;
  height: 1.8vw;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.7vw;
  font-weight: 500;
  color: #384B9D;
  outline: 0;
  border: none;
  border-radius: 0.3vw;
  cursor: pointer;
  margin-left: 0.4vw;
  border: 1px solid #384B9D;
  background: #FBFBFB;
}

.staff-button-updated {
  width: 6vw;
  height: 1.8vw;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.7vw;
  font-weight: 500;
  color: #FBFBFB;
  background: #384B9D;
  outline: 0;
  border: none;
  border-radius: 0.3vw;
  cursor: pointer;
  margin-left: 0.4vw;
  border: 1px solid #384B9D;
  background: #FBFBFB;
}

.staff-box {
  display: flex;
  justify-content: flex-start;
  width: 97%;
  margin-top: 4vw;
  height: auto;
  flex-wrap: wrap;
}

.staff-container {
  display: flex;
  flex-direction: column;
  width: 20.8vw;
  height: auto;
  box-shadow: 0px 3px 8px #bbbaba;
  border-radius: 0.3vw;
  margin-bottom: 2.5vw;
  margin-left: 3vw;
  /* margin-right: 2vw; */
  padding: 1vw 1vw;
}

.staff-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3vw;
  width: 98%;
  flex-wrap: wrap;
}

.staff-label-div {
  background: white;
  height: 3.8vw;
  display: flex;
  align-items: center;
  padding: 1.1vw;
  width: 20vw;
  border-radius: 1vw;
  box-shadow: 0px 3px 8px #bbbaba;
  color: #666666;
  font-size: 0.8vw;
  margin-bottom: 2vw;
}

.staff-date{
  /* background: #384B9D; */
  /* color: white; */
  border: none;
  outline: 0;
  font-size: 0.85vw;
}

.staff-left-button {
  width: 18vw;
  height: 2.1vw;
  margin-left: 0.6vw;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.85vw;
  font-weight: 500;
  line-height: 1vw;
  letter-spacing: 0em;
  color: #ffffff;
  background: #3850bd;
  outline: 0;
  border: none;
  border-radius: 0.3vw;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.staff-profile-data {
  display: flex;
  justify-content: flex-start;
  /* width: 110%; */
  /* align-items: center; */
  margin-top: 3vw;
  width: 98%;
  flex-wrap: wrap;
  /* height: auto; */
}

.staff-text-box {
  width: 30vw;
  outline: 0;
  border-radius: 1vw;
  padding: 1.1vw;
  border: 1px solid #d6d5d5;
  box-sizing: border-box;
  height: 3.8vw;
  box-shadow: 0px 3px 8px #bbbaba;
  font-size: 0.8vw;
  color: #666666;
  font-weight: 400;
  margin-bottom: 2vw;
  background-color: white;
}

.staff-profile-btn1 {
  /* display: flex;
  justify-content: end; */
  margin-right: 3vw;
  margin-top: 1vw;
  padding-bottom: 1vw;
}

.staff-profile-btn-container{
  display: flex;
  justify-content: end;
  align-items: center;
}

.staff-salary-cycle {
  width: 30vw;
  outline: 0;
  border-radius: 1vw;
  padding: 1.1vw;
  border: 1px solid #d6d5d5;
  box-sizing: border-box;
  height: 3.8vw;
  box-shadow: 0px 3px 8px #bbbaba;
  font-size: 0.8vw;
  color: #666666;
  font-weight: 400;
  margin-bottom: 2vw;
  background-color: white;
}

/* check... */
.inventory-text-box {
  /* width: 19vw;
  outline: 0;
  border-radius: 1vw;
  padding: 1.1vw;
  border: 1px solid #d6d5d5;
  box-sizing: border-box;
  height: 3.8vw;
  box-shadow: 0px 3px 8px #bbbaba; */
  font-size: 0.8vw;
  color: #666666;
  border: none;
  outline: 0;
  font-weight: 400;
  margin-bottom: 2vw;

  /* background-color: white; */
}
/* check... */


.entry-container{
  margin-top: 2vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 97%;
}

.home-entry-text-box {
  width: 32vw;
  outline: 0;
  border-radius: 1vw;
  padding: 1.1vw;
  border: 1px solid #d6d5d5;
  box-sizing: border-box;
  height: 3.8vw;
  box-shadow: 0px 3px 8px #bbbaba;
  font-size: 0.8vw;
  color: #666666;
  font-weight: 400;
}

.home-label-div {
  background: white;
  height: 3.8vw;
  display: flex;
  align-items: center;
  padding: 1.1vw;
  width: 32vw;
  border-radius: 1vw;
  box-shadow: 0px 3px 8px #bbbaba;
  color: #666666;
  font-size: 0.8vw;
  margin-bottom: 2vw;
}

.product-updated-input {
  font-size: 0.8vw;
  width: 36vw;
  border: none;
  outline: 0;
  color: #666666;
}

.invoice-txt-box {
  width: 19vw;
  outline: 0;
  border-radius: 1vw;
  padding: 1.1vw;
  border: 1px solid #d6d5d5;
  box-sizing: border-box;
  height: 3.8vw;
  box-shadow: 0px 3px 8px #bbbaba;
  font-size: 0.8vw;
  color: #666666;
  font-weight: 400;
  margin-bottom: 2vw;
  background-color: white;
  display: flex;
  align-items: center;
}

.invoice-updated-input {
  font-size: 0.8vw;
  width: 100%;
  border: none;
  outline: 0;
  color: #666666;
}

.invoice-update-label-div {
  background: white;
  height: 3.8vw;
  display: flex;
  align-items: center;
  padding: 1.1vw;
  width: 19vw;
  border-radius: 1vw;
  box-shadow: 0px 3px 8px #bbbaba;
  color: #666666;
  font-size: 0.8vw;
  margin-bottom: 2vw;
}

.select-attendance {
  width: 9vw;
  height: 2.1vw;
  margin-left: 1.5vw;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.85vw;
  font-weight: 500;
  line-height: 1vw;
  letter-spacing: 0em;
  color: #ffffff;
  background: #3850bd;
  outline: 0;
  border: none;
  border-radius: 0.3vw;
  cursor: pointer;
  padding: 0 1vw;
}

.attendance-label-div {
  /* background: white; */
  height: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vw;
  width: 9vw;
  /* border-radius: 1vw;   */
  box-shadow: 0px 3px 8px #bbbaba;
  color: #666666;
  font-size: 0.8vw;
  /* margin-bottom: 2vw; */
}

.shop-div {
  width: 20vw;
  height: 9vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(#8f9cd5, #4355a4);
  border-radius: 1vw;
}

.shop-div img{
  width: 1.5vw;
  height: 1.5vw;
  margin-bottom: 0.7vw;
}

.shop-container{
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  margin-top: 1.4vw;
  margin-bottom: 1vw;
  /* display: flex; */
  /* justify-content: space-between; */
  /* flex-direction: column; */
 
}

.shop-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
}

.shop-header-txt1{
  font-size: 1.2vw;
  color: #384B9D;
}

.shop-header-txt2{
  font-size: 0.9vw;
  color: #4E4E4E; 
}

.partner-card{
  margin-top: 1.3vw;
  width: 15vw;
  height: 14vw;
  /* border: 1px solid red; */
  box-shadow: -1px 3px 8px #bbbaba; 
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.partner-card img{
  width: 100%;
  height: 7.2vw;
}

.partner-card-txt1{
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  color: #384B9D;
  font-size: 1vw;
  height: 3vw;
  margin-top: 0.5vw;
}

.partner-card-txt2{
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  color: #000000;
  font-size: 0.7vw;
  margin-top: 0.4vw;
}

.star{
  width: 1vw;
  height: 1vw;
}

.rating{
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 0.8vw;
}

.partner-card-container{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 97%;
}

.category-card{
  margin-top: 1.3vw;
  width: 15vw;
  height: auto;
  /* border: 1px solid red; */
  box-shadow: -1px 3px 8px #bbbaba; 
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.category-card img{
  width: 100%;
  height: 100%;
}

.category-card-txt1{
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  color: #000000;
  font-size: 0.7vw;
  margin-bottom: 0.8vw;
  margin-top: 0.7vw;
}

.order-txt1 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 700;
  line-height: 2vw;
  color: #000000;
}

.order-txt2{
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 700;
  line-height: 2vw;
  color: #384B9D;
}

.order-txt3{
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.8vw;
  font-weight: 600;
  line-height: 2vw;
  color: #384B9D;
}

.profile-left-div{
   width: 30%;
   background-color: white;
   height: 30vw;
   /* margin-left: 1.5vw; */
   box-shadow: -1px 3px 8px #bbbaba;

}

.profile-right-div{
  width: 45%;
  background-color: white;
  height: 30vw;
  box-shadow: -1px 3px 8px #bbbaba;
}

.profile-left-div-img{
  width: 5vw;
  height: 5vw;
  border: 1.8px dotted #3850BD;
  border-radius: 90%;
  padding: 0.2vw;
  margin-top: 1vw;
}

.profile-txt1{
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 800;
  margin-top: 0.5vw;
  color: #384B9D;
}

.profile-txt2{
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.9vw;
  font-weight: 800;
  color: #000000;
  margin-bottom: 1vw;
}

.profile-txt3{
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 800;
  color: #384B9D;
  margin-bottom: 1vw;
  display: flex;
  align-items: center;
  margin-left: 1vw;
}

.profile-txt3 img{
  margin-right: 0.3vw;
  width: 1.2vw;
  height: 1.3vw;
}

.profile-txt4{
  font-family: "Nunito Sans", sans-serif;
  font-size: 1vw;
  font-weight: 800;
  color: #384B9D;
  display: flex;
  margin-bottom: 1vw;
  margin-left: 1vw;
  align-items: center;
}

.profile-txt4 img{
  margin-right: 0.3vw;
  width: 1.2vw;
  height: 1.3vw;
}

.logout-div{
  display: flex;
  margin-top: 1vw;
  margin-left: 1vw;
}

.logout-div img{
  margin-right: 0.3vw;
  width: 1.2vw;
  height: 1.6vw;
}

.profile-label-div {
  background: white;
  height: 3vw;
  display: flex;
  align-items: center;
  padding: 1.1vw;
  /* width: 33vw; */
  border-radius: 0.5vw;
  box-shadow: 0px 3px 8px #bbbaba;
  color: #666666;
  font-size: 0.8vw;
  margin-bottom: 1vw;
  border: 1px solid #a5a4a4;
}

.profile-input {
  font-size: 0.8vw;
  width: 100%;
  border: none;
  outline: 0;
  color: #666666;
}

.scf-img{
  width: 2vw;
  height: 2vw;
}
@import url("https://fonts.googleapis.com/css2?family=Biryani:wght@200;400;600;700&family=DM+Sans:opsz,wght@9..40,200&family=Inter:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Biryani:wght@200;400;600;700&family=DM+Sans:opsz,wght@9..40,200&family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,600&display=swap");

* {
  margin: 0;
  padding: 0;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.remove-arrow {
    -moz-appearance: textfield;
}

/* Login Box */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

